(function () {
  'use strict';

  /* @ngdoc object
   * @name home.postponements
   * @description
   *
   */
  angular
    .module('neo.home.postponements', [
      'ui.router',
      'neo.home.postponements.new',
      'neo.home.postponements.edit',
      'neo.home.postponements.postponedMatches'
    ]);
}());
